<div class="head-data" *ngIf="!unloadingCompleted">
    <div class="welcome-details">
        <img class="" src="/assets/images/ff_logo.svg">
        <p>Welcome to FreightFox!</p>
    </div>

    <form class="otp-genration-form" [formGroup]="loginForm">
        <div class="form-feild">
            <label>Enter Mobile number</label>
            <mat-form-field floatLabel='never' class="example-full-width">
                <input matInput formControlName="username" >
            </mat-form-field>
            <mat-error *ngIf="loginForm.get('username').invalid && loginForm.get('username').touched" class="text-danger">Enter Valid Email/Mobile number</mat-error>
            <p *ngIf="otpGenerated" class="otp-message">An OTP has been sent to {{loginForm.get('username').value}}</p>
            <mat-error *ngIf="errorData" class="">Something Went Wrong!</mat-error>
        </div>
        <div class="form-feild form-margin-top" *ngIf="otpGenerated">
            <label>Enter OTP</label>
            <mat-form-field floatLabel='never' class="example-full-width">
                <input matInput formControlName="otp" >
            </mat-form-field>
            <mat-error *ngIf="loginForm.get('otp').invalid && loginForm.get('otp').touched" class="text-danger">Enter Valid OTP</mat-error>
           <div class="ff_flex otp-data">
               <p class="remaning-time" ><span >{{remainingTime}}</span></p>
               <p class="resend-otp" ><span  [ngClass]="!otpInterval && !resendOtpFlag?'enabled':'disabled'" (click)="!otpInterval && !resendOtpFlag?resendOTP():''">Resend OTP</span></p>
           </div>
        </div>
        <div class="action form-margin-top">
            <button class="primary-btn" [disabled]="loginForm.invalid" (click)="otpGenerated?confirmOTP():generateOtp()">
                {{otpGenerated?'Confirm OTP':'Generate OTP'}}
            </button>
        </div>
    </form>
</div>

<div class="submitted-data default-page-padding" *ngIf="unloadingCompleted">
    <p>Thank you!<br>
        The details of POD have been recorded.</p>
</div>