import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReporterService } from '../reporter.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { DestinationReceiverService } from '../destination-receiver.service';

@Component({
  selector: 'app-otp-generation',
  templateUrl: './otp-generation.component.html',
  styleUrls: ['./otp-generation.component.scss']
})
export class OtpGenerationComponent implements OnInit {
  public loginForm: any
  otpGenerated: boolean = false;
  otpInterval: any;
  resendOtpFlag: boolean = false;
  documentId:any;
  remainingTime: any;
  completed: boolean = true;
  errorData:boolean = false;
  userId:any;
  unloadingCompleted : boolean = false;
  formType: 'DROP_TYPE' | 'DESTINATION_TYPE' = 'DROP_TYPE';

  constructor(
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public fb: UntypedFormBuilder, 
    private router: Router,
    private reporterService:ReporterService,
    private destinationReceiverService : DestinationReceiverService,
    private location: Location
  ) { }

  ngOnInit(): void {
    const currentPath = this.location.path();
    if(currentPath.includes('indent-destination')){
      this.formType = 'DESTINATION_TYPE';
    }
    this.route.params.subscribe(params => {
      this.documentId = params.id;
    })
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.pattern('^[1-9][0-9]{9}$')]],
    });
    this.checkUnloadingStatus();
  }


  checkUnloadingStatus(){
    let unloadingObservable: Observable<any>;
    if(this.formType == 'DROP_TYPE'){
      unloadingObservable = this.reporterService.checkUnloadingStatus(this.documentId);
    }else if(this.formType == 'DESTINATION_TYPE'){
      unloadingObservable = this.destinationReceiverService.checkUnloadingStatus(this.documentId);
    }
    unloadingObservable
    .subscribe(
      (data: any) => {
        if (data.id) {
          this.unloadingCompleted = true;
        }
      },
      (error: any) => {
        //
      }
    );
  }

  generateOtp() {
    this.errorData = false;
    
    let otpObservable: Observable<any>;
    let payload = {
      "mobileNumber": this.loginForm.value.username
    }

    if(this.formType == 'DROP_TYPE'){
      otpObservable = this.reporterService.sendOTP(this.documentId, payload);
    }else if(this.formType == 'DESTINATION_TYPE'){
      otpObservable = this.destinationReceiverService.sendOTP(this.documentId, payload);
    }

    otpObservable.subscribe(
      (data: any) => {
        this.userId = data.id;
        this.requestForOTP();
        this.loginForm.addControl('otp', new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9][0-9]*$')]));  
      },
      (error: any) => {
        this._snackBar.open(error.error, 'X',{
          duration: 7000
        });
      }
    );

  }

  requestForOTP() {
    this.otpGenerated = true;
    if (!this.resendOtpFlag) {
      let startTimer = 30;
      this.otpInterval = setInterval(() => {
        this.remainingTime = "00:" + ("" + startTimer).padStart(2, '0');
        startTimer--;
        if (startTimer < 0) {
          clearInterval(this.otpInterval);
          this.otpInterval = null;
        }
      }, 1000);
    }
    this.completed = true;
  }

  confirmOTP(){
    let payload = {
      "otp": this.loginForm.value.otp
    }

    let confirmOtpObservable: Observable<any>;
    if(this.formType == 'DROP_TYPE'){
      confirmOtpObservable = this.reporterService.login(this.userId, payload);
    }else if(this.formType == 'DESTINATION_TYPE'){
      confirmOtpObservable = this.destinationReceiverService.login(this.userId, payload);
    }

    confirmOtpObservable.subscribe(
      (data: any) => {
        this.router.navigate(["/lorry-receipt/details",this.formType,this.documentId,this.userId,this.loginForm.value.otp]);      
      },
      (error: any) => {
        this._snackBar.open("Something went Wrong", 'X',{
          duration: 5000
        });
      }
    );
  }

  resendOTP() {
    this.resendOtpFlag = true;
    this.loginForm.get('otp').reset();
    let payload = {};

    let resendOtpObservable: Observable<any>;
    if(this.formType == 'DROP_TYPE'){
      resendOtpObservable = this.reporterService.resendOTP(this.userId, payload);
    }else if(this.formType == 'DESTINATION_TYPE'){
      resendOtpObservable = this.destinationReceiverService.resendOTP(this.userId, payload);
    }

    resendOtpObservable.subscribe(
      (data: any) => {
        this._snackBar.open("OTP has been sent", 'X',{
          duration: 5000
        });
        this.resendOtpFlag = false;
        this.requestForOTP();     
      },
      (error: any) => {
        this._snackBar.open("Something went Wrong", 'X',{
          duration: 5000
        });
      }
    );
  }

}
