<div class="page-content">
    <div class="submitted-data default-page-padding" *ngIf="submittedData">
        <p>Thank you!<br>
            The details of POD have been recorded.</p>
    </div>
    <form [formGroup]="tripMaterialForm" *ngIf="!submittedData">
        <p class="trip-details grey-background-line default-page-padding">Trip Details</p>
        <div class="veh-details default-page-padding">
            <p class="veh-data">
                {{tripData?.truckNumber}}&nbsp;&nbsp; | &nbsp;&nbsp;LR No. - 
                {{formType == 'DESTINATION_TYPE' ? tripData?.lorryReceipt?.id : tripData?.dropPoint?.lorryReceipt?.lorryReceiptNumber}}
            </p>
            <p *ngIf="formType == 'DROP_TYPE'" class="od-data">
                <b>{{tripData?.originWarehouseName}}</b> - {{tripData?.dropPoint?.destinationWarehouseName}}, {{tripData?.dropPoint?.destinationCity}}
            </p>
            <p *ngIf="formType == 'DESTINATION_TYPE'" class="od-data">
                <b>{{tripData?.lorryReceipt?.originWarehouseName}}</b> - {{tripData?.warehouseName}}, {{tripData?.city}}
            </p>
        </div>
        <p class="trip-details grey-background-line default-page-padding">Reporting In Date & Time</p>
        <div class="date-time-class default-page-padding">
            <mat-form-field appearance="outline" class="width-100 no-padding-left custom-datepicker">
                <input matInput formControlName="destReportedDate" [max]="currentDate" (dateChange)="addEvent('change', $event)" [matDatepicker]="picker" placeholder="Choose  Date"
                    required readonly (click)="picker.open()">
                <mat-datepicker-toggle matSuffix [for]="picker">
                    <img matDatepickerToggleIcon class="blue"
                        src="https://console.freightfox.ai/assets/images/calender-icon.svg">
                    <img matDatepickerToggleIcon class="grey"
                        src="https://console.freightfox.ai/assets/images/calendar-grey.svg">

                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="default-page-padding">
            <div #customTip  class="width-100 time-picker">
                <div class="ff_flex">
                    <mat-form-field appearance="outline" class="width-100 ">
                        <input #dateInput (click)="showToolTip = true;"  matInput formControlName="destReportedTime"
                            placeholder="Select Time" readonly required>
                    </mat-form-field>
                    <app-time-picker *ngIf="showToolTip" [restrictFuture]="true" 
                        [dateSelected]="tripMaterialForm.get('destReportedDate').value" 
                        class="custom-time-picker top-margin-neg-45"
                        (onTimePicked)="timePicked($event)">
                    </app-time-picker>
                </div>
            </div>
        </div>

        <p class="trip-details grey-background-line default-page-padding">Unloading Point Observations</p>

        <div class="unloading-point " formArrayName="materialDispatchInfo">
            <div class="data"
                *ngFor="let material of tripMaterialForm?.get('materialDispatchInfo')?.controls; let i = index;"
                [formGroupName]="i">
                <div class="material-info">
                    <p class="shipping-quantity">{{ material.get('quantity').value }}</p>
                    <p class="shipping-unit">{{ material.get('shippingUnit').value }}</p>,
                    <p class="shipping-unit">{{ material.get('material').value }}</p>
                </div>
                <div class="dispatch-details ff_flex">
                    <div class="ff_flex shortage-leakage ">
                        <input placeholder="Damage" class="" type="number"
                            [ngClass]="material.get('breakageQuantity').errors ?'quanity-error-state':''"
                            formControlName="breakageQuantity">
                        <input placeholder="Shortage" class="" type="number"
                                [ngClass]="material.get('shortageQuantity').errors ?'quanity-error-state':''"
                                formControlName="shortageQuantity">
                    </div>
                </div>

            </div>

        </div>

        <p class="trip-details grey-background-line default-page-padding">Other Charges & Remarks</p>
        <div class="other-charges">
            <div class="repacking-form-feild">
                <label>Other Charges</label>
                <input formControlName="repackagingCharges" placeholder="Enter Repacking Charges">

                <mat-error
                    *ngIf="tripMaterialForm.get('repackagingCharges').invalid && tripMaterialForm.get('repackagingCharges').touched">

                    <mat-error *ngIf="tripMaterialForm.get('repackagingCharges').errors?.pattern" class="text-danger">
                        Other Charges is invalid</mat-error>
                </mat-error>
            </div>
            <div class="repacking-form-feild margin-top-form">
                <label>Remarks</label>
                <textarea formControlName="remarks" rows="4"></textarea>
            </div>
            <div class="images-div">
                <label>Images</label>
                <div *ngFor="let image of tripMaterialForm.value.qcDocs; let qcIndex = index;" class="qc-image">
                    <p class="qc-image-text">{{qcIndex+1}}. {{image.documentName}}</p>
                </div>
                <div *ngIf="tripMaterialForm.value.qcDocs.length < 5" class="cp-doc-wrapper ff_topM_5">
                    <div class="attachment-wrapper">
                        <div class="upload-file">
                            <img src="/assets/images/attachment-logo.svg">
                            <label class="label">
                                <input #multiDocFile type="file" (change)="uploadFile($event)">
                                Upload File
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <p *ngIf="isUploadingImage" class="loading-image-info">
                Uploading Image ...
            </p>
            <p *ngIf="errorMessage" class="loading-image-info">
                {{errorMessage}}
            </p>
            <div class="repacking-form-feild margin-top-form">
                <label>Receiver Details</label>
                <input formControlName="reporterDetails" placeholder="Enter Name/Number/Designation ">
            </div>
        </div>

    </form>
</div>




<div class="footer  default-page-padding">
    <img src="/assets/images/ff_logo.svg">
    <button [disabled]="checkValidity()" *ngIf="!submittedData" (click)="submitDetails()"
        class="primary-btn">Submit</button>
</div>