import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { OtpGenerationComponent } from './otp-generation/otp-generation.component';
import { TripDetailsComponent } from './trip-details/trip-details.component';
const routes: Routes = [
  { path: 'lorry-receipt/:id/unloading-form', component: OtpGenerationComponent },
  { path: 'indent-destination/:id/observation', component: OtpGenerationComponent },
  { path: 'lorry-receipt/details/:type/:id/:phNo/:otp', component: TripDetailsComponent },
 
  { path: '', redirectTo: 'lorry-receipt/:id/unloading-form', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    preloadingStrategy :PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }