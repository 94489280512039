import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/internal/operators';
import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DestinationReceiverService {
  baseUrl = environment.baseUrl;
  s3Url = environment.s3baseUrl;
  conciergeUrl = environment.conciergeUrl;

  constructor(
    private router: Router,
    private http: HttpClient
  ) { }

  sendOTP(id: number, data: any): Observable<[any]> {
    let headers = new HttpHeaders();
    return this.http.post<any>(this.conciergeUrl + `/concierge/api/v1/indent-destination/${id}/receiver`, data, { headers: headers })
      .pipe(
        map(
          (data) => {
            return data;
          }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;

  }

  checkUnloadingStatus(id: any): Observable<[any]> {
    let headers = new HttpHeaders();
    return this.http.get<any>(this.conciergeUrl + `/concierge/api/v1/indent-destination/${id}/observation`, { headers: headers })
      .pipe(
        map(
          (data) => {
            return data;
          }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }

  resendOTP(id: any, payload): Observable<[any]> {
    let headers = new HttpHeaders();
    return this.http.post<any>(this.conciergeUrl + `/concierge/api/v1/receiver/${id}/resend-otp`, payload, { headers: headers })
      .pipe(
        map(
          (data) => {
            return data;
          }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }

  login(id: any, data: any): Observable<[any]> {
    let headers = new HttpHeaders();
    return this.http.post<any>(this.conciergeUrl + `/concierge/api/v1/verify/receiver/${id}`, data, { headers: headers })
      .pipe(
        map(
          (data) => {
            return data;
          }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;

  }

  addUnloadingDetails(id: any, data: any): Observable<[any]> {
    let headers = new HttpHeaders();
    return this.http.post<any>(this.conciergeUrl + `/concierge/api/v1/receiver/${id}/observation`, data, { headers: headers })
      .pipe(
        map(
          (data) => {
            return data;
          }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;

  }

  uploadfile(key, file) {
    let options = new HttpHeaders()
    options.append("Content-Type", "multipart/form-data");
    var formdata = new FormData();
    formdata.append("file", file, file.name);
    return this.http
      .post<any>(`${this.s3Url}doraemon/api/v1/document`, formdata)
      .pipe(
        retry(1),
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error.error);
  }
}
