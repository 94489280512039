<div class="customToolTip">
    <div  class="toolTipView">
        <div class="time-selector">
            <div class="hour">
                <div class="title">HOUR</div>
                <ul class="list">
                    <li 
                        class="list-li"
                        (click)="selectHour(hour)"
                        [ngClass]="{'active': selectedHour === hour}" 
                        *ngFor="let hour of timeHour">
                        {{hour}}
                    </li>
                </ul>
            </div>
            <div class="minutes">
                <div class="title">MINUTES</div>
                <ul class="list">
                    <li 
                        class="list-li"
                        (click)="selectMin(min)"
                        [ngClass]="{'active': selectedMinute === min}"
                        *ngFor="let min of timeMinutes">
                        {{min}}
                    </li>
                </ul>
            </div>
            <div class="time-zone">
                <div class="title no-right-border">TIME ZONE</div>
                <ul class="list no-right-border">
                    <li 
                        class="list-li"
                        (click)="selectTimeZone(zone)"
                        [ngClass]="{'active': selectedZone === zone}"
                        *ngFor="let zone of timeZone">
                        {{zone}}
                    </li>
                </ul>
            </div>
        </div>
        <!-- <div class="submit-button">
            <button 
                [disabled]="!(selectedHour >=0 && selectedMinute>=0 && selectedZone)"
                (click)="submitTime()" class="btn-primary">
                Done  
            </button>
        </div>  -->
    </div>
</div>