import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
  
  selectedHour: any;
  selectedMinute: any;
  selectedZone: any;
  timeHour: any = [];
  timeMinutes: any = [];
  timeZone: any = ['AM', 'PM'];
  time: any;
  afterNoon: boolean = false;

  @ViewChild('customTip', { static: false }) menu: ElementRef;
  @Output() onTimePicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() dateSelected: string;
  @Input() restrictFuture: boolean;

  constructor(
    private renderer: Renderer2,
  ) {
    for (let i = 1; i <= 12; i++) {
      this.timeHour.push(i);
    }
    for (let i = 0; i < 60; i++) {
      this.timeMinutes.push(i);
    }
  }

  ngOnInit() {
    if (this.dateSelected && !this.restrictFuture) {
      // let currentTime = new Date(2021, 7, 12, 13, 30, 30, 0);
      let currentTime = new Date();
      let placeDate = new Date(this.dateSelected);
      if ((placeDate.getDate() === currentTime.getDate())
        && (placeDate.getMonth() === currentTime.getMonth())
        && (placeDate.getFullYear() === currentTime.getFullYear())) {
        let hrs = currentTime.getHours();
        let mins = currentTime.getMinutes();

        if (hrs >= 12) {
          this.timeZone = ['PM'];
          this.afterNoon = true;
          this.timeHour = [];
          for (let i = (hrs - 12); i < 12; i++) {
            if (i == 0) {
              this.timeHour.push(12);
            } else {
              this.timeHour.push(i);
            }
          }
          this.timeHour.sort((a, b) => a - b);
          if (mins > 59 && hrs != 12) {
            this.timeHour.shift();
          } else if (hrs == 12 && mins > 59) {
            this.timeHour.pop();
          }
        } else if (hrs < 12) {
          this.timeZone = ['AM', 'PM'];
        }
      } else {
        this.timeHour = [];
        for (let i = 1; i <= 12; i++) {
          this.timeHour.push(i);
        }
        this.timeZone = ['AM', 'PM'];
      }
    }

    if (this.dateSelected && this.restrictFuture) {
      let placeDate = new Date(this.dateSelected);
      // let currentTime = new Date(2021, 7, 12, 13, 30, 30, 0);
      let currentTime = new Date();
      let hrs = currentTime.getHours();
      let mins = currentTime.getMinutes();
      if ((placeDate.getDate() === currentTime.getDate())
        && (placeDate.getMonth() === currentTime.getMonth())
        && (placeDate.getFullYear() === currentTime.getFullYear())) {
        if (hrs >= 12) {
          this.timeZone = ['AM', 'PM'];
          this.afterNoon = true;
          this.timeHour = [];
          for (let i = 1; i <= 12; i++) {
            this.timeHour.push(i);
          }
          this.timeHour.sort((a, b) => a - b);
        } else if (hrs < 12) {
          this.timeHour = [];
          for (let i = 1; i <= hrs; i++) {
            this.timeHour.push(i);
          }
          this.timeZone = ['AM'];
        }
      } else {
        this.timeHour = [];
        for (let i = 1; i <= 12; i++) {
          this.timeHour.push(i);
        }
        this.timeZone = ['AM', 'PM'];
      }
    }
  }

  isTodaysDate() {
    // let currentTime = new Date(2021, 7, 12, 13, 30, 30, 0);
    let currentTime = new Date();
    let placeDate = new Date(this.dateSelected);
    return ((placeDate.getDate() === currentTime.getDate())
      && (placeDate.getMonth() === currentTime.getMonth())
      && (placeDate.getFullYear() === currentTime.getFullYear()));
  }

  selectHour(hour) {
    this.selectedHour = hour;
    // let currentTime = new Date(2021, 7, 12, 13, 30, 30, 0);
    let currentTime = new Date();
    let hrs = currentTime.getHours();
    let mins = currentTime.getMinutes();
    if (this.dateSelected && !this.restrictFuture) {
      if (!this.afterNoon) {
        if (this.isTodaysDate()) {
          if ((this.selectedHour < hrs && hrs < 12) || (this.selectedHour == 12 && hrs != 0)) {
            if (this.selectedZone == 'AM') {
              this.selectedZone = '';
            }
            this.timeZone = ['PM'];
          }
          else if ((this.selectedHour >= hrs && hrs < 12) || (hrs == 0)) {
            this.timeZone = ['AM', 'PM'];
          }
        }
      }
      if (this.isTodaysDate() && this.dateSelected) {
        if (currentTime.getHours() >= 12) { //for PM
          let hourSelected;
          if (this.selectedHour != 12) {
            hourSelected = 12 + this.selectedHour;
          } else {
            hourSelected = this.selectedHour;
          }
          if (hourSelected == currentTime.getHours()) {
            this.timeMinutes = [];
            let currentMin = currentTime.getMinutes();
            for (let i = (currentMin + 1); i < 60; i++) {
              this.timeMinutes.push(i);
            }
          } else {
            this.timeMinutes = [];
            for (let i = 0; i < 60; i++) {
              this.timeMinutes.push(i);
            }
          }
        }
        else {  //for AM
          this.timeMinutes = [];
          for (let i = 0; i < 60; i++) {
            this.timeMinutes.push(i);
          }
        }
      }
    }
    else if (this.dateSelected && this.restrictFuture && this.isTodaysDate) {
      if (this.afterNoon) { 
        let hourSelected; // 24 hour format
        if (this.selectedHour != 12) {
          hourSelected = 12 + this.selectedHour;
        } else {
          hourSelected = this.selectedHour;
        }
        if (hourSelected > hrs) {
          this.timeZone = ['AM'];
        }else{
          this.timeZone = ['AM', 'PM'];
        }
        this.timeMinutes = [];
        for (let i = 0; i < 60; i++) {
          this.timeMinutes.push(i);
        }
      } else {
        if (this.selectedHour == hrs) {
          this.timeMinutes = [];
          for (let i = 0; i < mins; i++) {
            this.timeMinutes.push(i);
          }
        } else {
          this.timeMinutes = [];
          for (let i = 0; i < 60; i++) {
            this.timeMinutes.push(i);
          }
        }
      }
    }

    if (this.selectedMinute) {
      this.selectMin(this.selectedMinute);
    }
    this.checkClose();
  }

  selectMin(minutes) {
    this.selectedMinute = minutes;
    // let currentTime = new Date(2021, 7, 12, 13, 30, 30, 0);
    let currentTime = new Date();
    let hrs = currentTime.getHours();
    let mins = currentTime.getMinutes();

    if (this.dateSelected && !this.restrictFuture && this.isTodaysDate()) {
      if (((this.selectedHour == hrs) || (this.selectedHour == 12 && hrs == 0)) && this.selectedMinute < mins) {
        if (this.selectedZone == 'AM') {
          this.selectedZone = '';
        }
        this.timeZone = ['PM'];
      } else if (((this.selectedHour == hrs) || (this.selectedHour == 12 && hrs == 0)) && this.selectedMinute >= mins) {
        if (!this.afterNoon) {
          this.timeZone = ['AM', 'PM'];
        }
      }
    }
    else if (this.dateSelected && this.restrictFuture && this.isTodaysDate()) {
      if (this.afterNoon) {
        let hourSelected = (this.selectedHour != 12) ? (12 + this.selectedHour) : this.selectedHour;
        if ((hourSelected >= hrs && this.selectedMinute > mins)) {
          this.timeZone = ['AM'];
          if (this.selectedZone == 'PM') {
            this.selectedZone = '';
          }
        } else if (hourSelected <= hrs && this.selectedMinute < mins) {
          this.timeZone = ['AM', 'PM'];
        }
        if (hourSelected < hrs) {
          this.timeZone = ['AM', 'PM'];
        }
      }
    }
    this.checkClose();
  }

  selectTimeZone(zone) {
    this.selectedZone = zone;
    this.checkClose();
  }

  submitTime() {
    this.time = {
      hour: this.selectedHour,
      minute: this.selectedMinute,
      zone: this.selectedZone
    };
    this.onTimePicked.emit(this.time);
  }

  checkClose() {
    if (this.selectedHour && (this.selectedMinute || this.selectedMinute == 0) && this.selectedZone) {
      setTimeout(() => {
        this.submitTime();
      }, 150);
    }
  }

}
