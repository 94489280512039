import { ElementRef, Renderer2 } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/internal/operators';
import { DestinationReceiverService } from '../destination-receiver.service';
import { ReporterService } from '../reporter.service';

@Component({
  selector: 'app-trip-details',
  templateUrl: './trip-details.component.html',
  styleUrls: ['./trip-details.component.scss']
})
export class TripDetailsComponent implements OnInit {
  documentId: any;
  otp: any;
  userId: any;
  tripData: any;
  submittedData:boolean = false;
  tripMaterialForm: any;
  date:any = new Date;
  showToolTip:boolean = false;
  showToolTipUnloadingTime:boolean = false;
  currentDate:any = new Date();
  supportedfiletype: Array<string> = ['pdf', 'png', 'jpg', 'jpeg', 'JPG', 'JPEG'];
  isUploadingImage:boolean = false;
  errorMessage: String = '';
  formType: 'DROP_TYPE' | 'DESTINATION_TYPE' = 'DROP_TYPE';

  @ViewChild('customTip', { static: false }) menuStart: ElementRef;
  @ViewChild('customTipUnloadingDiv', { static: false }) timeUnloadingDiv: ElementRef;
  @ViewChild('multiDocFile', { static: false }) multiDocFile: ElementRef;
  listenerFn: () => void;

  constructor( 
      private renderer: Renderer2,
      private route: ActivatedRoute, 
      public fb: UntypedFormBuilder, 
      private router: Router, 
      private reporterService: ReporterService,
      private destinationReceiverService: DestinationReceiverService,
    ) {
    this.listenerFn = this.renderer.listen('window', 'click', (e: Event) => {
        if (this.showToolTip && !this.menuStart.nativeElement.contains(e.target)) {
          this.showToolTip = false;
        }
        if (this.showToolTipUnloadingTime && !this.timeUnloadingDiv.nativeElement.contains(e.target)) {
          this.showToolTipUnloadingTime = false;
        }
    });
   }
  
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.documentId = params.id;
      this.userId = params.phNo;
      this.otp = params.otp;
      this.formType = params.type;
    })
    const currentDate = new Date();
    let hours:any = currentDate.getHours();
    let minutes:any = currentDate.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    // Convert hours from 24-hour format to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    hours = hours.toString().padStart(2, '0');
    minutes = minutes.toString().padStart(2, '0');
    let time = hours + ':' + minutes + ' ' + ampm;

    this.tripMaterialForm = this.fb.group({
      materialDispatchInfo: this.fb.array([]),
      'destReportedDate': ['', Validators.required],
      'destReportedTime': ['', Validators.required],
      'unloadingDate': [currentDate, Validators.required],
      'unloadingTime': [time, Validators.required],
      'repackagingCharges': ['', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      'remarks': [''],
      'reporterDetails': [''],
      'qcDocs': [[]],
    });
    this.checkUnloadingStatus();
  }

  checkUnloadingStatus() {
    let unloadingObservable: Observable<any>;
    if(this.formType == 'DROP_TYPE'){
      unloadingObservable = this.reporterService.checkUnloadingStatus(this.documentId);
    }else if(this.formType == 'DESTINATION_TYPE'){
      unloadingObservable = this.destinationReceiverService.checkUnloadingStatus(this.documentId);
    }
    unloadingObservable
    .subscribe(
      (data: any) => {
        if (data.id) {
          this.submittedData = true;
        } else {
          this.getTripDetails();
        }
      },
      (error: any) => { }
    );
  }

  reportedDateEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.tripMaterialForm.get('destReportedTime').reset();
  }
  
  unloadedDateEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.tripMaterialForm.get('unloadingTime').reset();
  }

  timePicked(event, type: 'REPORTED' | 'UNLOADING') {
    let hour = event.hour.toString().padStart(2, '0');
    let minute = event.minute.toString().padStart(2, '0');
    let zone = event.zone.toString().padStart(2, '0');
    let time = hour + ':' + minute + ' ' + zone;
    switch(type){
      case 'REPORTED':
        this.showToolTip = false;
        this.tripMaterialForm.get('destReportedTime').patchValue(time);
        break;
      case 'UNLOADING':
        this.showToolTipUnloadingTime = false;
        this.tripMaterialForm.get('unloadingTime').patchValue(time);
        break;
    }
  }

  convertDateTime(date, time) {
    let timeArray = time.split(/[ :]+/);
    let zone = timeArray[2];
    let minute = timeArray[1];
    let newDate = new Date(date);
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    // convert to 24 hours
    let hour24 = timeArray[0];
    if (zone === 'PM') {
        if (hour24 !== "12") {
            hour24 = 12 + parseInt(hour24);
        }
    } else if (zone === 'AM') {
        if (hour24 === "12") {
            hour24 = 0;
        }
    }
    // "startTime": "2020-12-28T09:30:00+05:30"
    let convertedDate = year + '-' + ("0" + month).slice(-2)
        + '-' + ("0" + day).slice(-2) + 'T'
        + ("0" + hour24).slice(-2) + ':' + ("0" + minute).slice(-2)
        + ":00+05:30";

    return convertedDate;
}

  getTripDetails() {
    let payload = {
      "otp": this.otp
    }

    let confirmOtpObservable: Observable<any>;
    if(this.formType == 'DROP_TYPE'){
      confirmOtpObservable = this.reporterService.login(this.userId, payload);
    }else if(this.formType == 'DESTINATION_TYPE'){
      confirmOtpObservable = this.destinationReceiverService.login(this.userId, payload);
    }

    confirmOtpObservable
    .subscribe(
      (data: any) => {
        this.tripData = data;
        this.patchTripData();
      },
      (error: any) => {
        //
      }
    );
  }

  uploadFile(event) {
    let type = event.target.files[0].type;
    let size = event.target.files[0].size;
    if (!this.supportedfiletype.includes(type.split('/')[1])) {
      return;
    }
    if (size > 20097152) {
      // TODO : add snackbar
      this.errorMessage = 'File size is greater than 20 MB.'
      return;
    }
    else {
      this.errorMessage = '';
      this.isUploadingImage = true;
      this.reporterService.uploadfile(type, event.target.files[0])
        .pipe(
          catchError((error: any) => {
            return throwError(error);
          }),
        )
        .subscribe(data => {
          let documentData = {
            "documentId": data.id,
            "documentKey": data.key,
            "documentName": event.target.files[0].name
          }
          this.tripMaterialForm.get('qcDocs').value.push(documentData);
          this.multiDocFile.nativeElement.value = '';
          this.isUploadingImage = false;
        });
    }
  }

  patchTripData() {
    let destinations;
    if(this.formType == 'DROP_TYPE'){
      destinations = this.tripData.dropPoint;
    }else if(this.formType == 'DESTINATION_TYPE'){
      destinations = this.tripData;
    }
    const materials = this.tripMaterialForm.get('materialDispatchInfo') as FormArray;
    destinations.materials.forEach((material: any) => {
      if(material.quantity && material.quantity > 0){
        let form = this.fb.group({
          id: [material.id],
          weight: material.weight ? [material.weight] : [],
          totalWeight: material.totalWeight ? [material.totalWeight] : [],
          material: material.material ? [material.material] : material.name ? [material.name] : [],
          sku: material.sku ? [material.sku] : [],
          quantity: [material.quantity],
          breakageQuantity: ['', [Validators.pattern('[0-9]*')]],
          leakageQuantity: ['', [Validators.pattern('[0-9]*')]],
          shortageQuantity: ['', [Validators.pattern('[0-9]*')]],
          shippingUnit: [material.shippingUnit ? material.shippingUnit : material.qtyUnit]
        });
        materials.push(form);
      }
    });
  }

  resetBottelCan(material: any) {
    material.get('breakageQuantity').reset();
    material.get('shortageQuantity').reset();
    material.get('leakageQuantity').reset();
  }

  submitDetails() {
    let materials: any = [];    
    const materialsFormData = this.tripMaterialForm.get('materialDispatchInfo') as FormArray;
    materialsFormData.value.forEach((material: any) => {
      let materialData:any = {
        materialId: material.id,
        material: material.material,
        quantity: material.quantity,
        shippingUnit: material.shippingUnit,
      };
      if(material.sku){
        materialData.sku = material.sku;
      }
      if(material.breakageQuantity){
        materialData.breakageQuantity = material.breakageQuantity;
      }
      if(material.leakageQuantity){
        materialData.leakageQuantity = material.leakageQuantity;
      }
      if(material.shortageQuantity){
        materialData.shortageQuantity = material.shortageQuantity;
      }
      materials.push(materialData);
    });
    
    let convertedTimeUnloaded = this.convertDateTime(this.tripMaterialForm.value.unloadingDate ,this.tripMaterialForm.value.unloadingTime);
    let convertedTimeReported = this.convertDateTime(this.tripMaterialForm.value.destReportedDate,this.tripMaterialForm.value.destReportedTime);
    let unloadingDetails = {
      destReportedTime: convertedTimeReported,
      unloadingTime: convertedTimeUnloaded,
      materialDetails: materials,
      repackagingCharges: this.tripMaterialForm.value.repackagingCharges,
      remarks: this.tripMaterialForm.value.remarks,
    }
    if(this.tripMaterialForm.value.reporterDetails){
      unloadingDetails['reporterDetails'] = this.tripMaterialForm.value.reporterDetails
    }
    if(this.tripMaterialForm.value.qcDocs){
      unloadingDetails['observationDocuments'] = this.tripMaterialForm.value.qcDocs;
    }

    let unloadingDetailsObservable: Observable<any>;
    if(this.formType == 'DROP_TYPE'){
      unloadingDetailsObservable = this.reporterService.addUnloadingDetails(this.userId, unloadingDetails);
    }else if(this.formType == 'DESTINATION_TYPE'){
      unloadingDetailsObservable = this.destinationReceiverService.addUnloadingDetails(this.userId, unloadingDetails);
    }

    unloadingDetailsObservable.subscribe(
      (data: any) => {
        this.submittedData = true;
      },
      (error: any) => { }
    );
  }

  checkValidity() {
    let disbaled = true;

    if (this.tripMaterialForm.value.destReportedDate && this.tripMaterialForm.value.destReportedTime) {
      disbaled = false;
      this.tripMaterialForm.get('materialDispatchInfo').value.forEach((trip: any) => {
        if(trip.bottle || trip.cans){
          if(!trip.breakageQuantity && !trip.leakageQuantity && !trip.shortageQuantity ){
            disbaled = true;
          }
        }
       
      });
    }
    if(this.isUploadingImage){
      disbaled = true;
    }

    return disbaled;
  }

}

